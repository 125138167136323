import showPopup from './modals';
import initObserver from './general/observer';
import Cookies from 'js-cookie';

export default function () {
    const modalContentsHolder = document.querySelector(".custom-modal-contents");
    const cookieSentinel = document.querySelector('.privacy-sentinel');

    if (!modalContentsHolder || !cookieSentinel || Cookies.get('cookie_custom_modal_closed') === '1') return;

    initObserver(cookieSentinel, function () {
        setTimeout(function () {

            showPopup({
                header: 'Powiadomienie od sklepu',
                body: modalContentsHolder.innerHTML,
                closeCallback: function () {
                    Cookies.set('cookie_custom_modal_closed', '1', { expires: 1 });
                },
            });
            
        }, 100);
    });
}