import { on } from 'delegated-events';

import { cartCommand } from './cart-commands';
import showPopup from '../modals';

export function maskProductsAmount(selector) {
    const inputs = document.querySelectorAll(selector);

    if (inputs.length === 0) return;

    import('imask').then((module) => {
        inputs.forEach(input => {
            const maskOptions = {
                mask: Number,
                min: 1,
                max: 9999
            };

            module.default(input, maskOptions);
        });
    });
}

export default function () {
    maskProductsAmount('.cart-product-amount input[type="text"]');

    on('click', '.cart-product-remove button', function () {
        const tr = this.closest('.cart-product');
        tr.parentElement.removeChild(tr);
        recalculateQuantity();
    });

    on('keydown', '#voucher-value', function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.nextElementSibling.click();
        }
    });

    on('keydown', '.cart-product-amount input', function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            recalculateQuantity();
        }
    });

    on('click', '.cart-select-wrapper li.selected label', function (e) {
        e.preventDefault();
    });

    const cartCheckAll = document.querySelector('#cart-check-all');

    if (cartCheckAll) {
        const needCartCheckAll = cartCheckAll.closest('fieldset').querySelectorAll('.checking-reg:not(.cart-check-all)').length > 1;

        if (needCartCheckAll) {
            cartCheckAll.closest('fieldset').classList.add('has-many-checkboxes');
        }
    }

    on('change', '#cart-check-all', function () {
        const isChecked = this.checked;
        console.log("Detected overall button is", isChecked);
        const allCheckboxes = this.closest('fieldset').querySelectorAll('input[type="checkbox"]:not(#cart-check-all)');
        console.log("Found checkboxes are", allCheckboxes);

        allCheckboxes.forEach(checkbox => {
            console.log("Single button item is ", checkbox);
            if (isChecked != checkbox.checked) {
                console.log("Clicking on ", checkbox);
                checkbox.click();
            }
        });
    });

    on('click', '.pick-payment-method', function () {
        showPopup({ header: 'Wybierz kanał płatności', body: this.nextElementSibling.innerHTML, overloadClass: 'payment-channels-modal' });
    });

    on('click', '.recalculate-all', function () {
        recalculateQuantity();
    });

    on('click', '.cart-product-amount button', function () {
        const inputField = this.parentElement.querySelector("input");
        const inputValue = Number(inputField.value);

        if (isNaN(inputValue)) {
            inputField.value = 1;
            recalculateQuantity();
            return;

        }

        if (this.classList.contains('plus')) {
            inputField.value = inputValue + 1;
        }

        if (this.classList.contains('minus')) {
            if (inputValue > 1)
                inputField.value = inputValue - 1;
            else return;
        }

        recalculateQuantity();
    });

    // Odliczanie na ostatnim kroku koszyka
    const paymentRedirectButton = document.getElementById('auto-redirect');

    if (paymentRedirectButton) {
        let seconds = +document.querySelector('.cart-final-payment').getAttribute('data-payment-timer');
        paymentRedirectButton.querySelectorAll('em')[0].textContent = seconds;

        const timer = setInterval(function () {
            paymentRedirectButton.querySelectorAll('em')[0].textContent = --seconds;

            if (seconds === 0) {
                paymentRedirectButton.click();
                clearInterval(timer);
            }
        }, 1000);

        paymentRedirectButton.addEventListener('click', function (e) {
            if (!paymentRedirectButton.getAttribute("href")) {
                e.preventDefault();
                document.getElementById('payment-start').submit();
            }
        });
    }
}

function recalculateQuantity() {
    const items = Array.from(document.querySelectorAll("[data-cart-item]")).map(element => {

        return {
            key: element.getAttribute("data-cart-item"),
            quantity: !isNaN(element.value) ? +element.value : 1
        }
    });

    cartCommand("quantity", JSON.stringify(items));
}