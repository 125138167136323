﻿import { on } from 'delegated-events';

import { debounce, isVisible } from '../utils/utils';

export default function () {
    const body = document.body;
    let winWidth = window.innerWidth;

    let updateWidth = debounce(function () {
        winWidth = window.innerWidth;
    }, 100);

    window.addEventListener('resize', updateWidth);

    // TREE
    const tree = document.getElementById('tree');

    if (tree) {
        document.querySelector('.tree-trigger').addEventListener('click', function (e) {
            if (winWidth < 1280) {
                e.stopPropagation();

                if (!tree.querySelector('.close-tree-header')) {
                    tree.firstElementChild.firstElementChild.insertAdjacentHTML('afterbegin', '<span class="tree-header close-tree-header">Menu</span>');
                }

                const menuRwdWrapper = document.querySelector('.cms-rwd');
                const mainmenu = document.querySelector('.mainmenu');

                if (mainmenu && menuRwdWrapper && menuRwdWrapper.innerHTML === '') {
                    menuRwdWrapper.innerHTML = mainmenu.innerHTML;
                }

                body.classList.toggle('show-menu');
            }
        });

        document.querySelector('.tree-canvas').addEventListener('click', function () {
            if (winWidth < 1280) {
                body.classList.remove('show-menu');
            }
        });

        on('click', '#tree .category-header', function () {
            const closestUl = this.closest('ul');
            const wrappingUl = closestUl.parentElement.parentElement;

            closestUl.classList.remove('active');
            wrappingUl.classList.remove('hide');
        });

        on('click', '#tree .close-tree-header', function () {
            body.classList.remove('show-menu');
        });

        on('click', '#tree a', function (e) {
            if (winWidth < 1280) {
                if (this.parentElement.classList.contains('parent')) {
                    e.preventDefault();

                    const nextUl = this.nextElementSibling;
                    const closestUl = this.closest('ul');
                    const parentName = nextUl.getAttribute('data-parent-name');
                    const parentUrl = nextUl.getAttribute('data-parent-url');

                    if (nextUl.firstElementChild.tagName != 'SPAN') {
                        nextUl.insertAdjacentHTML('afterbegin', `<span class="tree-header category-header">${parentName}</span>`);
                        nextUl.firstElementChild.insertAdjacentHTML('afterend', `<a class="tree-category-all-link" href="${parentUrl}">Pokaż wszystkie</a>`);
                    }

                    closestUl.classList.add('hide');
                    nextUl.classList.add('active');
                    nextUl.scrollIntoView();
                }
            }
        });
    }

    // SEARCHBOX
    const searchEngineTrigger = document.querySelector('.search-engine-trigger');
    const searchEngineClose = document.querySelector('.search-engine-close');

    if (searchEngineTrigger && searchEngineClose) {
        searchEngineTrigger.addEventListener('click', function () {

            body.classList.add('show-search-engine');
            setTimeout(function () {
                document.querySelector('#search-engine input[name="query"]').focus();
            }, 300);

            if (winWidth < 1280) {
                body.classList.add('overlay-visible');
            }
        });

        searchEngineClose.addEventListener('click', function () {
            body.classList.remove('overlay-visible');
            body.classList.remove('show-search-engine');
        });
    }
}