export default function () {
    const freeShipment = document.querySelector('.free-shipment-info');
    if (!freeShipment) return;

    const freeShipmentValue = parseFloat(freeShipment.getAttribute('data-free-shipment-value'));
    const currentPaymentValue = parseFloat(document.querySelector('#cart-box > span').getAttribute('data-current-payment-value'));

    calculateShipment();
    progressBarInit();

    function calculateShipment() {
        const diff = +(freeShipmentValue - currentPaymentValue).toFixed(2);

        if (diff > 0) {
            freeShipment.innerHTML = `Do darmowej dostawy brakuje <b class='on'>${diff.toFixed(2).replace('.', ',')} zł</b>`;
        } else {
            freeShipment.innerHTML = `<b class="on">Dostawa gratis!</b>`;
        }
    }

    function progressBarInit() {
        const progressBar = document.querySelector(".free-shipment-container .progress-bar");
        const rawPercentage = +(currentPaymentValue / freeShipmentValue * 100);
        let percentage = Math.floor(rawPercentage * 100) / 100; // rounds down with 2 decimals

        if (percentage >= 100) {
            percentage = 100;
            const cs = getComputedStyle(document.documentElement);
            const successColor = cs.getPropertyValue('--successColorForJS');
            progressBar.style.backgroundColor = successColor;
        }

        const fullWidth = progressBar.parentElement.offsetWidth;
        const width = (percentage / 100) * fullWidth;
        progressBar.style.width = `${(width).toFixed(0)}px`;
    }

}