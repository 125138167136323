import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { bannerVideosInitialize, hideVideo } from './banner-videos';

export default function () {
    window.addEventListener('load', () => {
        const anySwiper = document.querySelector('.swiper:not(.product-images)');

        if (!anySwiper) return;

        import('swiper').then((module) => {
            const Swiper = module.default;
            const cs = getComputedStyle(document.documentElement);

            //MAIN BANNER
            const mainBanner = new Swiper('.main-banner', {
                modules: [Autoplay, Pagination],
                preloadImages: false,
                autoplay: {
                    delay: 5000
                },
                lazy: true,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                on: {
                    beforeTransitionStart: function (instance) {
                        const currentSlide = instance.slides[instance.activeIndex];
                        const slideImg = currentSlide.querySelector('img');
                        
                        if (slideImg.hasAttribute('src')) return;

                        currentSlide.classList.add('loaded');
                        slideImg.setAttribute('src', slideImg.getAttribute('data-src'));
                    },
                    slideChangeTransitionEnd: function (instance) {

                        if (instance.autoplay && !instance.autoplay.running) {
                            instance.autoplay.start();
                        }

                        const previousSlide = instance.slides[instance.previousIndex];
                        if (previousSlide.querySelector("iframe")) {
                            hideVideo(previousSlide);
                        }
                    }
                }
            });
            bannerVideosInitialize(mainBanner);

            //SLIDER BANNER
            const breakpoints = {
                1: {},
                530: {},
                900: {},
                1100: {},
                1280: {}
            };

            const slider1 = new Swiper('.sliding-banners-wrapper .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider1-button',
                    prevEl: '.swiper-button-prev.slider1-button'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--mainpageSliderGap').replace('px', ''),
            });

            slider1.on('breakpoint', function () {
                slider1.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider1.params.spaceBetween = +cs.getPropertyValue('--mainpageSliderGap').replace('px', '');
                slider1.update();
            });

        });
    }, false);
}