export function bannerVideosInitialize(sliderObject) {
    if (!sliderObject) return;
    const sliderElement = sliderObject.hostEl;
    if (!sliderElement) return;
    const allSlides = sliderElement.querySelectorAll(".swiper-slide");
    if (!allSlides) return;

    allSlides.forEach((slide) => {
        slide.button = slide.querySelector(".slide-button");
        slide.container = slide.querySelector(".text-wrapper-container");
        if (!slide.button || !slide.container) return;

        slide.href = slide.button.getAttribute("href");
        if (!slide.href || !slide.href.includes('vimeo.com')) return;

        const regexCheck = /(?:vimeo\.com\/(?:video\/)?|vimeo\.com\/)(?:channels\/\w+\/)?(\d+)/;
        let matchSlices = slide.href.match(regexCheck);
        if (!matchSlices) return;
        slide.videoID = matchSlices[1];
        if (!slide.videoID) return;

        slide.button.classList.add("plays-video");
        slide.button.addEventListener('click', playVideo);

        function playVideo(event) {

            event.preventDefault();

            if (sliderObject.autoplay) {
                sliderObject.autoplay.stop();
            }

            slide.container.classList.add("hide-text");

            const iframeHTML = `
                <iframe src="https://player.vimeo.com/video/${slide.videoID}?autoplay=1&amp;loop=1&amp;controls=0"
                width="1440" height="640" frameborder="0" allow="autoplay"></iframe>
            `;
            slide.container.insertAdjacentHTML('beforeend', iframeHTML);

            slide.parentElement.querySelectorAll("img").forEach(img => { img.classList.add("hide") });
        };

    });
}

export function hideVideo(slide) {

    slide.querySelector("iframe").remove();
    slide.parentElement.querySelectorAll("img").forEach(img => { img.classList.remove("hide") });
    slide.querySelector(".text-wrapper-container").classList.remove("hide-text");
}