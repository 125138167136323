import showPopup from './modals';

export default function () {
    const popupTrigger = document.querySelector(".shipment-popup-trigger");
    const modalContentsHolder = document.querySelector(".shipment-popup-contents");

    if (!popupTrigger || !modalContentsHolder) return;

    popupTrigger.addEventListener('click', function () {

        showPopup({ header: 'Formy dostawy', body: modalContentsHolder.innerHTML });

    });
}